import React, { useState, useEffect, useRef } from 'react'
import { CircularProgress, Tooltip } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import moment from 'moment'
import axios from 'axios'
import _ from 'lodash'

import Abilities from './Abilities'
import Input from 'components/Input'
import Button from 'components/Button'
import FamilyDetail from './FamilyDetail'
import NoAccess from 'components/NoAccess'
import { Info } from '@mui/icons-material'
import UploadFiles from 'components/Upload'
import NothingToShow from '../NothingToShow'
import DeleteIcon from 'assets/icons/delete'
import DatePicker from 'components/DatePicker'
import { strengths, weaknesses } from './data'
import EditSkills from 'components/EditSkills'
import { ShowToast } from 'utils/toastMessages'
import { fetchUser } from 'store/reducers/user'
import { translation } from 'utils/germanTranslation'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import VerifyEditAccess from 'components/VerifyEditAccess'
import useGetStudentPersonalData from 'hooks/useGetStudentPersonalData'
import SimpleEditWrapper from 'components/EditSkills/SimpleEditWrapper'
import { user as userFromState } from 'store/reducers/user'

const EditPersonalDetail = () => (
  <VerifyEditAccess>
    {(id, canEdit, navigate, isClassTeacher, isUserFromShareProfile) =>
      canEdit ? (
        <VerifiedEditPersonalDetail
          id={id}
          navigate={navigate}
          isUserFromShareProfile={isUserFromShareProfile}
        />
      ) : (
        <NoAccess />
      )
    }
  </VerifyEditAccess>
)

const referenceInitialState = {
  firstName: '',
  lastName: '',
  designation: '',
  contactNumber: '',
  email: '',
}

const initialValue = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  nationality: '',
  phone: '',
  postalCode: '',
  address: '',
  place: '',
  fatherFirstName: '',
  fatherLastName: '',
  fatherOccupation: '',
  motherFirstName: '',
  motherLastName: '',
  motherOccupation: '',
  siblings: [],
  strengths: [],
  weaknesses: [],
  dateOfBirth: null,
  aboutMeVideo: {},
  references: [],
}

const VerifiedEditPersonalDetail = ({
  id,
  navigate,
  isUserFromShareProfile,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()

  const [weaknessOptions, setWeaknessOptions] = useState(weaknesses)
  const [strengthOptions, setStrengthOptions] = useState(strengths)
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)

  const { personalData, loading: personalDataLoading } =
    useGetStudentPersonalData(id)

  let referencesRef = useRef(null)
  const toastId = useRef(null)

  useEffect(() => {
    if (location.hash === '#references' && !personalDataLoading) {
      setTimeout(() => {
        if (!referencesRef.current) return
        referencesRef.current.scrollIntoView({
          block: 'nearest',
          inline: 'start',
          behavior: 'smooth',
        })
      }, 100)
    }
  }, [location, personalDataLoading])

  useEffect(() => {
    if (!_.isEmpty(personalData)) setValue(personalData)
  }, [personalData])

  const pageTitle = 'Persönliche Informationen'

  const handleChange = (newValue, field) => {
    setValue({
      ...value,
      [field]: field === 'type' ? newValue : newValue || '',
    })
  }

  const handleReferenceChange = (newValue, field, index) => {
    setValue({
      ...value,
      references: value.references.map((object, i) =>
        index === i ? { ...object, [field]: newValue } : object
      ),
    })
  }

  const handleDateChange = (parsedDate, enteredValue) => {
    const date =
      parsedDate !== 'Invalid date'
        ? moment(parsedDate, 'DD/MM/YYYY')
        : moment(enteredValue, 'DD/MM/YYYY')
    setValue({
      ...value,
      dateOfBirth: date['_d'],
    })
  }

  const handleSubmit = async () => {
    for (const key in value) {
      if (
        value[key] === null ||
        value[key] === undefined ||
        value[key] === '' ||
        value[key].length === 0
      ) {
        ShowToast({
          type: 'info',
          message: `Du hast noch nicht alle Felder ausgefüllt.`,
        })
        break
      }
    }
    setLoading(true)
    try {
      const personalData = {
        ...value,
        nationality: value.nationality,
        siblings: JSON.stringify(value.siblings),
        strengths: JSON.stringify(value.strengths),
        weaknesses: JSON.stringify(value.weaknesses),
        references: JSON.stringify(value.references),
      }
      const { data } = await axios.put(
        BACKEND_URL + API_URL.personalData.add,
        { data: personalData },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id,
          },
        }
      )
      ShowToast({ type: 'success', message: data?.message })
      dispatch(fetchUser())
      navigate(-1)
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleVideoUpload = (file) => {
    const formData = new FormData()
    formData.append('video', file)
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        id: value?.id,
      },
      onUploadProgress: (progressEvent) => {
        if (!toastId.current) {
          toastId.current = toast('Uploading...', { autoClose: false })
        }
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        if (percent < 100) {
          toast.update(toastId.current, {
            render: `Uploading ${percent}%`,
            type: toast.TYPE.INFO,
            autoClose: false,
          })
        }
        if (percent === 100) {
          toast.update(toastId.current, {
            render: translation.uploadComplete,
            type: toast.TYPE.SUCCESS,
            autoClose: 2000,
          })
          toastId.current = null
        }
      },
    }
    axios
      .post(BACKEND_URL + API_URL.user.uploadVideo, formData, config)
      .then(({ data }) => {
        handleChange(data.data, 'aboutMeVideo')
      })
      .catch((error) => {})
  }

  return (
    <>
      {personalDataLoading ? (
        <CircularProgress />
      ) : (
        <>
          <EditSkills
            pageTitle={pageTitle}
            onUpdateClick={handleSubmit}
            disabled={loading}
          >
            <SimpleEditWrapper
              showAddIcon={false}
              customized={true}
              pageTitle={pageTitle}
            >
              <div className='flex flex-col xs:flex-row items-center gap-3'>
                <Button
                  title={translation.cancel}
                  titleClasses='text-[#458EFF] text-lg font-semibold'
                  containerClasses='rounded-md w-full sm:w-[118px] h-[60px] bg-[#E9F1FC]'
                  onClick={() => navigate(-1)}
                />
                <Button
                  title={translation.update}
                  onClick={handleSubmit}
                  titleClasses='text-white text-lg font-semibold'
                  containerClasses='rounded-md w-full sm:w-[138px] h-[60px] bg-[#458EFF]'
                  disabled={loading}
                />
              </div>
              <div className='grid grid-cols-none sm:grid-cols-3 lg:grid-cols-4 gap-6 items-center '>
                <Input
                  label={translation.firstName}
                  value={value?.firstName}
                  handleChange={(newValue) =>
                    handleChange(newValue, 'firstName')
                  }
                  placeholder='Max'
                />
                <Input
                  label={translation.lastName}
                  placeholder='Muster'
                  value={value?.lastName}
                  handleChange={(newValue) =>
                    handleChange(newValue, 'lastName')
                  }
                />
                <Input
                  label={translation.contactNumber}
                  placeholder='077 491 24 04'
                  value={value.phone}
                  handleChange={(newValue) => handleChange(newValue, 'phone')}
                />
                <Input
                  label={
                    <h1 className='flex items-center gap-2'>
                      {translation.email}
                      {!isUserFromShareProfile && (
                        <Tooltip
                          enterTouchDelay={0}
                          title='Du kannst deine Mail unter Konto-Einstellungen ändern.'
                        >
                          <Info />
                        </Tooltip>
                      )}
                    </h1>
                  }
                  placeholder='maxmuster@mustermail.ch'
                  type='email'
                  handleChange={(newValue) => {}}
                  className='bg-[#EFEFEF] text-black cursor-not-allowed'
                  value={value.email}
                />
                <DatePicker
                  label={translation.dateOfBirth}
                  inputFormat='dd/MM/yyyy'
                  value={value.dateOfBirth}
                  disableFuture
                  handleChange={(...props) => handleDateChange(...props)}
                />
                <Input
                  label={translation.postalCode}
                  placeholder='6010'
                  value={value.postalCode}
                  handleChange={(newValue) =>
                    handleChange(newValue, 'postalCode')
                  }
                />
                <Input
                  label={translation.address}
                  placeholder='Musterstrasse 33'
                  value={value.address}
                  handleChange={(newValue) => handleChange(newValue, 'address')}
                />
                <Input
                  label={translation.place}
                  placeholder='Kriens'
                  value={value.place}
                  handleChange={(newValue) => handleChange(newValue, 'place')}
                />
                <Input
                  label={translation.nationality}
                  placeholder='Schweiz/Spanien'
                  value={value.nationality}
                  handleChange={(newValue) =>
                    handleChange(newValue, 'nationality')
                  }
                />
                <UploadFiles
                  label='Über mich Video'
                  file={
                    _.isEmpty(value?.aboutMeVideo) ? null : value?.aboutMeVideo
                  }
                  onDeleteFile={() => handleChange('', 'aboutMeVideo')}
                  handleFileUpload={(file) => handleVideoUpload(file)}
                  types={[
                    'mp4',
                    'mkv',
                    'webm',
                    'avi',
                    'mov',
                    'flv',
                    'wmv',
                    '3gp',
                    'mpeg',
                  ]}
                />
              </div>
            </SimpleEditWrapper>

            <FamilyDetail
              value={value}
              setValue={setValue}
              handleChange={handleChange}
              onUpdateClick={handleSubmit}
              isLoading={loading}
            />

            <Abilities
              value={value}
              setValue={setValue}
              strengthOptions={strengthOptions}
              weaknessOptions={weaknessOptions}
              setStrengthOptions={setStrengthOptions}
              setWeaknessOptions={setWeaknessOptions}
              onUpdateClick={handleSubmit}
              isLoading={loading}
            />

            {/* Add References */}
            <div ref={referencesRef}>
              <SimpleEditWrapper
                heading={
                  <div className='flex items-start sm:items-center gap-4 flex-col sm:flex-row'>
                    <h1>{translation.references}</h1>
                    <span className='text-xs xs:text-sm text-gray-500 border rounded-full px-2'>
                      In der Regel führt man zwei Referenzen im Lebenslauf auf
                    </span>
                  </div>
                }
                pageTitle={translation.references}
                customized={true}
                addAnotherField={() => {
                  setValue({
                    ...value,
                    references: [...value.references, referenceInitialState],
                  })
                }}
                showAddIcon={value.references.length < 2}
              >
                {value.references.length > 0 ? (
                  value.references.map((item, index) => (
                    <div
                      key={index}
                      className='flex items-start lg:items-center flex-col lg:flex-row gap-2'
                    >
                      <div className='flex gap-2 flex-col sm:flex-row w-full lg:w-auto'>
                        <Input
                          label={translation.firstName}
                          labelClasses={
                            index === 0 ? 'block' : 'sm:hidden block'
                          }
                          value={item.firstName}
                          handleChange={(newValue) =>
                            handleReferenceChange(newValue, 'firstName', index)
                          }
                          placeholder={'Mike'}
                        />
                        <Input
                          label={translation.lastName}
                          labelClasses={
                            index === 0 ? 'block' : 'sm:hidden block'
                          }
                          value={item.lastName}
                          handleChange={(newValue) =>
                            handleReferenceChange(newValue, 'lastName', index)
                          }
                          placeholder={'Muster'}
                        />
                      </div>
                      <div className='flex gap-2 flex-col sm:flex-row w-full lg:w-auto'>
                        <Input
                          label={translation.designation}
                          labelClasses={
                            index === 0 ? 'block' : 'sm:hidden block'
                          }
                          value={item.designation}
                          handleChange={(newValue) =>
                            handleReferenceChange(
                              newValue,
                              'designation',
                              index
                            )
                          }
                          placeholder={'Klassenlehrer'}
                        />
                        <Input
                          label={translation.contactNumber}
                          labelClasses={
                            index === 0 ? 'block' : 'sm:hidden block'
                          }
                          value={item.contactNumber}
                          type='tel'
                          handleChange={(newValue) =>
                            handleReferenceChange(
                              newValue,
                              'contactNumber',
                              index
                            )
                          }
                          placeholder={'077 491 24 04'}
                        />
                      </div>
                      <div className='grid grid-cols-none sm:grid-cols-2 w-full lg:flex lg:w-[350px] '>
                        <Input
                          label={translation.email}
                          labelClasses={
                            index === 0 ? 'block' : 'sm:hidden block'
                          }
                          value={item.email}
                          type='email'
                          handleChange={(newValue) =>
                            handleReferenceChange(newValue, 'email', index)
                          }
                          placeholder={'mikemuster@mail.com'}
                        />
                      </div>
                      <div
                        className={`flex items-center justify-center w-full lg:w-auto ${
                          index === 0 && 'pt-0 sm:pt-5'
                        }`}
                        onClick={() =>
                          setValue({
                            ...value,
                            references: [
                              ...value.references.slice(0, index),
                              ...value.references.slice(index + 1),
                            ],
                          })
                        }
                      >
                        <DeleteIcon />
                      </div>
                    </div>
                  ))
                ) : (
                  <NothingToShow title='Bisher noch keine Referenz hinzufügt' />
                )}
              </SimpleEditWrapper>
            </div>
          </EditSkills>
        </>
      )}
    </>
  )
}
export default EditPersonalDetail
