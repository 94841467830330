import React, { useState } from 'react'
import axios from 'axios'
import moment from 'moment'
import { Box, CircularProgress } from '@mui/material'

import Button from 'components/Button'
import CustomModal from 'components/CustomModal'
import { Link, useParams } from 'react-router-dom'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import { ROUTES } from 'utils/routes'
import { ShowToast } from 'utils/toastMessages'
import VerifyAdmin from '../VerifyAdmin'
import { translation } from 'utils/germanTranslation'
import NothingToShow from 'components/Student/NothingToShow'
import useFetchAllBanners from 'hooks/useFetchAllBanners'
import EnhancedTable from './TableSort'
import { bannerTypeMapping } from './constants'
import CustomInput from 'components/Input'

const WebBanner = () => (
  <VerifyAdmin>
    <VerifiedWebBanner />
  </VerifyAdmin>
)

const modalDataInitialState = {
  id: '',
  title: '',
  open: false,
}

const createData = (
  id,
  company,
  type,
  startDate,
  endDate,
  jobs,
  kantons,
  clicks
) => {
  return { id, company, type, startDate, endDate, jobs, kantons, clicks }
}

const headCells = [
  {
    id: 'company',
    numeric: false,
    disablePadding: false,
    label: 'Werbekunde',
  },
  {
    id: 'type',
    numeric: false,
    disablePadding: false,
    label: 'Bannertyp',
  },
  {
    id: 'startDate',
    numeric: false,
    disablePadding: false,
    label: 'Laufzeit Start',
  },
  {
    id: 'endDate',
    numeric: false,
    disablePadding: false,
    label: 'Laufzeit Start',
  },
  {
    id: 'jobs',
    numeric: false,
    disablePadding: false,
    label: 'Beruf',
  },
  {
    id: 'kantons',
    numeric: false,
    disablePadding: false,
    label: 'Werberegion',
  },
  {
    id: 'clicks',
    numeric: true,
    disablePadding: false,
    label: 'Clicks',
  },
]

const VerifiedWebBanner = () => {
  const { id: userId } = useParams()
  const [search, setSearch] = useState('')

  const { banners, loading, refetch } = useFetchAllBanners({
    company: search,
  })

  const [modalData, setModalData] = useState(modalDataInitialState)

  const handleBannerDelete = async () => {
    const url = BACKEND_URL + API_URL.banner.deleteBanner + modalData.id
    try {
      await axios.delete(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      setModalData(modalDataInitialState)
      refetch()
    } catch (error) {
      ShowToast({ message: error })
    }
  }

  const handleCloseModal = () => setModalData(modalDataInitialState)

  return (
    <div className='w-full h-full bg-softWhite'>
      <Box className='max-w-[1760px] min-h-screen px-4 pt-10 sm:px-8 w-full gap-4'>
        <div className='flex items-center justify-between pb-9'>
          <h3 className='text-2xl font-bold'>
            Werbebanner <span className='opacity-40'>({banners.length})</span>
          </h3>
          <Link to={ROUTES({ userId }).admin.banner.addBanner}>
            <Button
              title={`Banner ${translation.add}`}
              titleClasses='bg-[#458EFF] text-white font-semibold w-[156px] h-[56px] flex items-center justify-center rounded-md'
            />
          </Link>
        </div>
        <CustomInput
          placeholder='Search by company'
          value={search}
          handleChange={(value) => setSearch(value)}
          containerClasses='mb-6 w-[250px]'
        />

        {loading ? (
          <div className='flex items-center justify-center'>
            <CircularProgress />
          </div>
        ) : banners.length > 0 ? (
          <>
            <EnhancedTable
              userId={userId}
              setModalData={setModalData}
              headCells={headCells}
              rows={banners.map((banner) =>
                createData(
                  banner.id,
                  banner.company,
                  Object.keys(bannerTypeMapping).find(
                    (key) => bannerTypeMapping[key] === banner.type
                  ),
                  moment(banner.startDate).format('DD.MM.YYYY'),
                  moment(banner.endDate).format('DD.MM.YYYY'),
                  banner.jobs,
                  banner.isNational
                    ? [{ name: 'National Banner' }]
                    : banner.kantons,
                  banner.clicks
                )
              )}
            />
          </>
        ) : (
          <NothingToShow />
        )}
      </Box>
      <CustomModal
        open={modalData.open}
        handleClose={handleCloseModal}
        containerClasses='w-[90%] sm:w-auto'
      >
        <div>
          <p className='text-lg sm:text-xl font-semibold text-center'>
            Möchten Sie diesen Job wirklich entfernen{' '}
            <span className='text-blue-600 font-bold'>({modalData.title})</span>
            ?
          </p>
          <div className='flex justify-center gap-4 mt-4'>
            <Button
              title={translation.sure}
              onClick={handleBannerDelete}
              containerClasses='px-8 py-2 rounded-lg text-white'
              backgroundColor='#458EFF'
            />
            <Button
              title={translation.cancel}
              onClick={() => handleCloseModal()}
              containerClasses='px-6 py-2 rounded-lg text-[#458EFF]'
              backgroundColor='#E9F1FC'
            />
          </div>
        </div>
      </CustomModal>
    </div>
  )
}

export default WebBanner
