import { pdf } from '@react-pdf/renderer'
import axios from 'axios'
import { toast } from 'react-toastify'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import MotivationLetterPdf from './MotivationLetterPdf'
import { Apprenticeship } from './Step1'

export const updateMotivationLetter = async (
  value,
  setValue,
  setLoading,
  id,
  letterId,
  navigate,
  step,
  setStep,
  otherAttachments,
  user
) => {
  setLoading(true)
  let dividend = value.type === Apprenticeship ? 2 : 3
  setValue({
    ...value,
    progress: 100 * (step + 1 / dividend),
  })

  if (step !== 3) {
    setStep((prev) => prev + 1)
    return
  }
  value.attachments = [...value.attachments, ...otherAttachments]
  let link = document.createElement('a')

  const blob = await pdf(
    <MotivationLetterPdf value={value} user={user} />
  ).toBlob()

  link.href = URL.createObjectURL(blob)
  link.target = '_blank'
  link.click()

  try {
    const verb = letterId ? 'put' : 'post'
    const url = letterId
      ? `${API_URL.motivationLetters.updateById}${letterId}`
      : API_URL.motivationLetters.add
    const { data } = await axios[verb](
      BACKEND_URL + url,
      {
        value: {
          ...value,
          modifiedAt: new Date(),
        },
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          id,
        },
      }
    )
    toast.success(data.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
    navigate(-1)
  } catch (err) {
    toast.error(err.message, {
      position: 'top-center',
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      closeButton: false,
    })
    setLoading(false)
  }
}
