import React, { useState } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { CircularProgress, TableCell, TableHead, TableRow } from '@mui/material'

import CustomTabs from 'components/Tabs'
import AllCategoriesJobs from './AllCategoriesJobs'
import { LOCATION_NOT_SPECIFIED, jobCategories, jobTypes } from 'utils'
import useGetJobs from 'hooks/useGetJobs'
import { user } from 'store/reducers/user'
import CustomInput from 'components/Input'
import CustomTable from 'components/Table'
import { ROUTES } from 'utils/routes'
import CategoryJobs from './CategoryJobs'
import Navbar from 'components/Navbar/Navbar'
import NothingToShow from '../NothingToShow'
import SplitImageLink from '../JobDetail/BannerImage'
import useFetchAllBanners from 'hooks/useFetchAllBanners'
import postalCodeToKanton from 'utils/postalCodeToKantons.json'

import {
  JOB_OVERVIEW_PAGE,
  bannerTypeMapping,
} from 'components/Admin/Banner/constants'
import { BACKEND_URL } from 'utils/apiConstants'
import AskUserKanton from 'components/AskUserKanton'

export const JobsPagesHeading = () => (
  <h1 className='font-bold text-lg md:text-3xl py-10'>
    Informationen zu Jobs mit geringer Nachfrage
  </h1>
)

const StudentJobs = () => {
  const [params, setParams] = useSearchParams()
  const { id: userId, isLoggedIn, postalCode, role } = useSelector(user)
  const [search, setSearch] = useState()

  const { data, loading } = useGetJobs('', search)
  const userLocationFromCookies = localStorage.getItem('location')

  const {
    banners,
    loading: bannerLoading,
    refetch,
  } = useFetchAllBanners({
    type: bannerTypeMapping[JOB_OVERVIEW_PAGE],
    location:
      role === 'Teacher'
        ? userLocationFromCookies !== LOCATION_NOT_SPECIFIED
          ? userLocationFromCookies
          : undefined
        : isLoggedIn
        ? postalCodeToKanton[postalCode]?.kanton
        : userLocationFromCookies !== LOCATION_NOT_SPECIFIED
        ? userLocationFromCookies
        : undefined,
    isStarted: true,
    isExpired: false,
  })

  const currentSelectedJobType =
    jobTypes.findIndex((c) => c.includes(params.get('type'))) > -1
      ? jobTypes.findIndex((c) => c.includes(params.get('type')))
      : false

  const selectJobType = (type) => {
    if (type === params.get('type')) {
      setParams({
        category: params.get('category'),
        type: null,
      })
      return
    }
    if (params.get('category') === 'null' || !params.get('category'))
      setParams({
        category: jobCategories[0],
        type,
      })
    else
      setParams({
        category: params.get('category'),
        type,
      })
  }

  return (
    <>
      <Navbar />
      <AskUserKanton customHandleSelectLocation={refetch} />
      <div className='flex justify-center w-full h-full bg-softWhite min-h-screen'>
        <Box className=' max-w-[1760px] px-8 w-full'>
          <>
            <div className='flex flex-col sm:flex-row justify-between items-center w-full gap-6 pt-10'>
              <h1 className='font-bold text-lg md:text-3xl text-left'>
                Informationen über Lehrberufe
              </h1>
              {!bannerLoading && banners.length ? (
                <SplitImageLink
                  id={banners[0].id}
                  imageUrl={`${BACKEND_URL}/${banners[0].image}`}
                  firstLink={banners[0].firstUrl}
                  secondLink={banners[0].secondUrl}
                  splitDirection={banners[0].multiBanner}
                  containerClasses='max-w-[300px] w-full flex items-center justify-center mt-6'
                />
              ) : null}
              <CustomInput
                containerClasses='py-6'
                placeholder='Suche nach Berufsbezeichnung'
                width='w-96'
                value={search}
                handleChange={(newValue) => setSearch(newValue)}
              />
            </div>
            {!search ? (
              <>
                <CustomTabs
                  value={
                    jobCategories.findIndex(
                      (c) => c === params.get('category')
                    ) > -1
                      ? jobCategories.findIndex(
                          (c) => c === params.get('category')
                        )
                      : false
                  }
                  data={jobCategories}
                  onClick={(category) => {
                    if (category === params.get('category')) {
                      setParams({
                        category: null,
                        type: params.get('type'),
                      })
                      return
                    }
                    setParams({
                      category,
                      type: params.get('type'),
                    })
                  }}
                />
                <CustomTabs
                  value={
                    jobTypes.findIndex((c) => c.includes(params.get('type'))) >
                    -1
                      ? jobTypes.findIndex((c) =>
                          c.includes(params.get('type'))
                        )
                      : false
                  }
                  data={jobTypes}
                  onClick={(type) => selectJobType(type)}
                />

                {params.get('type') || params.get('category') ? (
                  loading ? (
                    <CircularProgress />
                  ) : (
                    <>
                      <CategoryJobs
                        jobs={data}
                        jobCategory={
                          params.get('category') !== 'null'
                            ? params.get('category')
                            : null
                        }
                        jobType={
                          params.get('type') !== 'null'
                            ? params.get('type')
                            : null
                        }
                      />
                    </>
                  )
                ) : (
                  <AllCategoriesJobs
                    jobs={data}
                    userId={userId}
                    jobCategory={
                      params.get('category') !== 'null'
                        ? params.get('category')
                        : null
                    }
                    jobType={
                      params.get('type') !== 'null' ? params.get('type') : null
                    }
                  />
                )}
              </>
            ) : data?.length > 0 ? (
              <CustomTable
                data={data}
                headerTemplate={
                  <TableHead className='bg-[#F0F2F9]'>
                    <TableRow>
                      <TableCell>Berufsbezeichnung</TableCell>
                      <TableCell>Kategorie</TableCell>
                    </TableRow>
                  </TableHead>
                }
                rowTemplate={(job) => (
                  <TableRow key={job?.id}>
                    <TableCell className='py-6'>
                      <Link
                        to={
                          ROUTES({
                            jobTitle: job.title,
                          }).student.job.jobByTitle
                        }
                      >
                        <h1 className='text-blue-500 font-bold hover:underline underline-offset-2'>
                          {job?.title}
                        </h1>
                      </Link>
                    </TableCell>
                    <TableCell className='py-6'>{job?.category}</TableCell>
                  </TableRow>
                )}
              />
            ) : (
              <NothingToShow />
            )}
          </>
        </Box>
      </div>
    </>
  )
}
export default StudentJobs
