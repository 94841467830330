import React from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import moment from 'moment'
import {
  ContentCopy,
  Download,
  UploadFile,
  Visibility,
} from '@mui/icons-material'
import { pdf } from '@react-pdf/renderer'
import axios from 'axios'
import { Box } from '@mui/system'
import { IconButton, Tooltip } from '@mui/material'

import EditIcon from 'assets/icons/edit'
import TrashIcon from 'assets/icons/trash'
import { ROUTES } from 'utils/routes'
import MotivationLetterPdf from '../EditMotivationLetter/MotivationLetterPdf'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'
import CustomModal from 'components/CustomModal'
import GenerateWordMotivationLetter from '../EditMotivationLetter/GenerateWordMotivationLetter'
import { ShowToast } from 'utils/toastMessages'
import { uuidv4 } from 'utils'

export const getLetterTitle = (letter, user) => {
  return letter?.type === 'upload'
    ? letter?.title
    : [
        'Motivationsschreiben',
        letter?.company?.name,
        letter?.job?.title,
        letter?.job?.type,
        `${user?.firstName} ${user?.lastName}`,
      ]
        .filter(Boolean)
        .join('_')
}

const MotivationLetterRowTemplate = ({
  index,
  row,
  userId,
  onDelete,
  user,
  student,
  refetch,
  isClassTeacher = false,
}) => {
  const [showDownloadModal, setShowDownloadModal] = React.useState(false)
  const title = getLetterTitle(row, user)

  const generatePDF = async (preview = false) => {
    let link = document.createElement('a')
    if (row?.type === 'upload') {
      const { data } = await axios.get(BACKEND_URL + '/' + row?.file?.path, {
        responseType: 'blob',
      })
      if (link.download !== undefined) {
        var url = URL.createObjectURL(data)
        link.setAttribute('href', url)
        if (preview) {
          link.setAttribute('target', '_blank')
        } else {
          link.setAttribute('download', row?.title)
        }
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
      return
    }
    const blob = await pdf(
      <MotivationLetterPdf value={row} user={student} />
    ).toBlob()

    link.href = URL.createObjectURL(blob)
    if (preview) {
      link.target = '_blank'
    } else {
      link.download = title
    }
    link.click()
  }

  const createDuplicateLetter = async () => {
    try {
      const verb = 'post'
      const url = API_URL.motivationLetters.add
      const { id, ...motivationLetter } = row
      await axios[verb](
        BACKEND_URL + url,
        {
          value: {
            id: uuidv4(),
            modifiedAt: new Date(motivationLetter.modifiedAt),
            ...motivationLetter,
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            id: userId,
          },
        }
      )
      refetch()
      ShowToast({
        type: 'success',
        message: 'Kopie erfolgreich erstellt',
      })
    } catch (err) {
      ShowToast({ message: 'Kopie erstellen fehlgeschlagen' })
    }
  }

  return (
    <>
      <TableRow
        key={index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell className='py-4' component='th' scope='row'>
          <div
            onClick={() => generatePDF(true)}
            className='cursor-pointer text-blue-500 hover:underline z-'
          >
            {title}
          </div>
        </TableCell>
        <TableCell className='py-4'>{row?.company?.name}</TableCell>
        <TableCell className='py-4'>{row?.job?.title}</TableCell>
        <TableCell className='py-4'>
          {moment(row?.modifiedAt).date()}.{moment(row?.modifiedAt).month() + 1}
          .{moment(row?.modifiedAt).year()}
        </TableCell>
        <TableCell className='py-4'>
          {row?.type === 'upload' ? <UploadFile /> : row?.type}
        </TableCell>

        <TableCell className='py-4 flex  justify-end'>
          {!isClassTeacher && (
            <Tooltip
              enterTouchDelay={0}
              title='Duplizieren (Erstelle & bearbeite eine Kopie)'
              className='p-[4px]'
            >
              <IconButton onClick={createDuplicateLetter}>
                <div className='cursor-pointer border border-[#99AECF] rounded-xl w-[40px] flex items-center justify-center text-black h-[40px]'>
                  <ContentCopy />
                </div>
              </IconButton>
            </Tooltip>
          )}

          <Tooltip enterTouchDelay={0} title='Vorschau' className='p-[4px]'>
            <IconButton onClick={() => generatePDF(true)}>
              <div className='cursor-pointer border border-[#99AECF] rounded-xl w-[40px] flex items-center justify-center text-black h-[40px]'>
                <Visibility />
              </div>
            </IconButton>
          </Tooltip>

          {row?.type !== 'upload' && !isClassTeacher && (
            <Link
              to={
                ROUTES({ userId, letterId: row?.id }).student.edit
                  .motivationLetterById
              }
            >
              <Tooltip
                enterTouchDelay={0}
                title='Bearbeiten'
                className='p-[4px]'
              >
                <IconButton>
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Link>
          )}

          {!isClassTeacher && (
            <Tooltip enterTouchDelay={0} title='Löschen' className='p-[4px]'>
              <IconButton onClick={onDelete}>
                <TrashIcon />
              </IconButton>
            </Tooltip>
          )}

          <Tooltip
            enterTouchDelay={0}
            title='Herunterladen'
            className='p-[4px]'
          >
            <IconButton
              onClick={() => {
                row?.type === 'upload'
                  ? generatePDF()
                  : setShowDownloadModal(true)
              }}
            >
              <div className='cursor-pointer border border-[#99AECF] rounded-xl w-[40px] flex items-center justify-center text-black h-[40px]'>
                <Download />
              </div>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>

      <CustomModal
        open={showDownloadModal}
        handleClose={() => setShowDownloadModal(false)}
        containerClasses={'max-w-[400px]'}
      >
        <Box className='p-2 sm:p-4 rounded-md '>
          <ul className='px-4'>
            <li
              onClick={() => generatePDF()}
              className='list-disc list-outside my-1 no-underline hover:underline underline-offset-1 hover:text-blue-700 cursor-pointer transition-all ease-linear'
            >
              Motivationsschreiben als PDF herunterladen
            </li>
            <GenerateWordMotivationLetter user={user} value={row} />
          </ul>
        </Box>
      </CustomModal>
    </>
  )
}

export default MotivationLetterRowTemplate
