import axios from 'axios'
import { useCallback } from 'react'
import { useState, useEffect } from 'react'
import { API_URL, BACKEND_URL } from 'utils/apiConstants'

const useFetchAllBanners = (filters) => {
  const { type, isNational, location, company, isStarted, isExpired } = filters

  const [banners, setBanners] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)

  const fetchAllBanners = useCallback(async () => {
    try {
      let url = BACKEND_URL + API_URL.banner.allBanners
      const response = await axios.get(url, {
        params: {
          type,
          ...(isNational ? { isNational } : { location }),
          company,
          isStarted,
          isExpired,
        },
      })

      setBanners(response.data)
      setLoading(false)
    } catch (error) {
      setError(error)
      setLoading(false)
    }
  }, [type, location, isNational, company, isStarted, isExpired])

  useEffect(() => {
    if (company) {
      const getData = setTimeout(() => {
        fetchAllBanners()
      }, 1000)

      return () => clearTimeout(getData)
    } else fetchAllBanners()
  }, [
    type,
    location,
    isNational,
    company,
    isStarted,
    isExpired,
    fetchAllBanners,
  ])

  return { banners, loading, error, refetch: fetchAllBanners }
}

export default useFetchAllBanners
