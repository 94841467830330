import AutoComplete from 'components/AutoComplete'
import CustomDatePicker from 'components/DatePicker'
import React from 'react'
import {
  JOB_OVERVIEW_PAGE,
  LANDING_PAGE_BANNER_TYPE,
  bannerTypeOptions,
} from './constants'
import { Checkbox, FormControlLabel, FormGroup, Switch } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { allKantons } from 'components/LandingPage/SelectKanton'

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const BannerSettings = ({
  kantons,
  type,
  jobs,
  startDate,
  endDate,
  isNational,
  handleChange,
  databaseJobs,
  handleDateChange,
}) => {
  const handleChangeNationalStatus = () =>
    handleChange(!isNational, 'isNational')

  return (
    <div className='flex flex-col gap-4'>
      <div>
        {/* Add Region MultiSelect */}
        <AutoComplete
          label='Werberegion'
          multiple
          controlled
          disabled={type === LANDING_PAGE_BANNER_TYPE || isNational}
          value={kantons}
          disableCloseOnSelect
          sx={{
            width: '100%',
          }}
          handleChange={(value) => handleChange(value, 'kantons')}
          options={allKantons.map((kanton) => kanton.name)}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 4, padding: 3 }}
                checked={selected}
              />
              {option}
            </li>
          )}
          helperText={
            (type === LANDING_PAGE_BANNER_TYPE &&
              `Not able to select jobs when banner type is ${type}`) ||
            (isNational && `Not able to select kantons when banner is national`)
          }
        />
        <FormGroup>
          <FormControlLabel
            disabled={type === LANDING_PAGE_BANNER_TYPE}
            checked={Boolean(isNational)}
            onChange={handleChangeNationalStatus}
            control={<Switch />}
            label='National Banner'
          />
        </FormGroup>
      </div>

      {/* Banner Type AutoComplete */}
      <AutoComplete
        label='Bannertyp'
        controlled
        options={bannerTypeOptions}
        value={type || null}
        sx={{
          width: '100%',
        }}
        handleChange={(value) => handleChange(value, 'type')}
      />

      {/* Jobs Autocomplete */}
      <AutoComplete
        label='Beruf'
        multiple
        controlled
        value={jobs}
        disableCloseOnSelect
        sx={{ width: '100%' }}
        disabled={
          type === LANDING_PAGE_BANNER_TYPE || type === JOB_OVERVIEW_PAGE
        }
        handleChange={(value) => handleChange(value, 'jobs')}
        options={databaseJobs?.map((job) => job.title)}
        renderOption={(props, option, { selected }) => (
          <li {...props}>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 4, padding: 3 }}
              checked={selected}
            />
            {option}
          </li>
        )}
        helperText={
          (type === LANDING_PAGE_BANNER_TYPE || type === JOB_OVERVIEW_PAGE) &&
          `Not able to select jobs when banner type is ${type}`
        }
      />

      <div className='flex items-center gap-4'>
        <CustomDatePicker
          label='Start date'
          value={startDate}
          inputFormat='dd/MM/yyyy'
          handleChange={(date) => handleDateChange(date, 'startDate')}
        />
        <CustomDatePicker
          label='End date'
          value={endDate}
          inputFormat='dd/MM/yyyy'
          handleChange={(date) => handleDateChange(date, 'endDate')}
        />
      </div>
    </div>
  )
}

export default BannerSettings
